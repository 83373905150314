<template>
  <div class="main primary_color">
    <!-- nav -->
    <my-nav
      :current="current"
      @onHandleShow="handleShow"
    ></my-nav>

    <!-- 全链营销 -->
    <son-banner :current="current"></son-banner>

    <!-- 一站式运营 -->
    <div id="product1" class="product1 column a_center w-per100 minh890">
      <div class="w-per62_5 column a_center">
        <div class="c_294A3B ft32 mt100 wow animate__animated animate__zoomIn" data-wow-duration="0.6s" data-wow-iteration="1" data-wow-delay="0.2s">
          我们为您提供一站式运营服务
        </div>

        <div class="w-per100 mt60 mb100 row wrap" :class="list.length>2?'j_sb':''">
          <div class="w-per48 minh280 pl30 pr100 pt30 pb46 br10 relative mt30 wow animate__animated animate__flipInX"
            :class="index%2==1?'':'mr40'"
            v-for="(item,index) in list" :key="index"
            :style="'background-image:url('+item.bg_img+');background-size: 100% 100%;background-repeat: no-repeat;'"
            data-wow-duration="0.6s" data-wow-iteration="1" :data-wow-delay="0.2 * (index+1)+'s'"
          >
            <div class="minw180 h60 ft24 row a_center nowrap" :style="'background-image:url('+item.thumb+');background-size: 9.375vw 100%;background-repeat: no-repeat;'">
              <span class="ml60 mr30">{{ item.title ? item.title : '' }}</span>
            </div>
            <div class="mt25 ft16 lh28 w-per62_5 info_color">
              {{ item.content ? item.content : '' }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 您的烦恼 -->
    <div id="product2" class="product2 column a_center w-per100 minh850 ft32">
      <div class="w-per62_5 column a_center">

        <div class="c_294A3B ft32 mt30 wow animate__animated animate__zoomIn" data-wow-duration="0.6s" data-wow-iteration="1" data-wow-delay="0.2s">
          您的烦恼
        </div>

        <!-- <div class="w-per100 mt60 mb50">
          <div class="w-per100 row wrap j_sb">
            <div class="w400 minh350 px20 pt30 pb180 br10 wow animate__animated animate__fadeInUp"
              :class="index%2?'':'mr200'"
              v-for="(item,index) in trouble.slice(0,2)" :key="'fannao'+index"
              :style="'background-image:url('+ 'https://www.daiweicd.com/commonImg/fannao' +(index + 1)+ '.png' +');background-size: 100% 100%;background-repeat: no-repeat;'"
              data-wow-duration="0.6s" data-wow-iteration="1" :data-wow-delay="0.2 * (index+1)+'s'"
            >
              <div class="row">
                <div class="wh50 row a_center nowrap">
                  <img class="wh50" :src="item.thumb" alt="">
                </div>
                <div class="ft20 ml10 text_nowrap">
                  {{ item.title ? item.title : '' }}
                </div>
              </div>
              <div class="mt10 ft16 lh24">
                {{ item.content ? item.content : '' }}
              </div>
            </div>
          </div>
          <div class="w-per100 row wrap j_sb" v-if="trouble.length>2">
            <div class="w400 minh350 mt135 px20 pt30 pb180 wow animate__animated animate__fadeInUp"
              :class="index%2?'':'mr200'"
              v-for="(item,index) in trouble.slice(2)" :key="'fannao'+(index + 2)"
              :style="'background-image:url('+ 'https://www.daiweicd.com/commonImg/fannao' +(index + 3)+ '.png' +');background-size: 100% 100%;background-repeat: no-repeat;'"
              data-wow-duration="0.6s" data-wow-iteration="1" :data-wow-delay="0.2 * (index+1)+'s'"
            >
              <div class="row a_center">
                <div class="wh50 row a_center nowrap">
                  <img class="wh50" :src="item.thumb" alt="">
                </div>
                <div class="ft20 ml10 text_nowrap">
                  {{ item.title ? item.title : '' }}
                </div>
              </div>
              <div class="mt10 ft16 lh24">
                {{ item.content ? item.content : '' }}
              </div>
            </div>
          </div>

        </div> -->

        <div class="w-per100 mt60 mb50">
          <div class="w-per100 row j_sb a_center">
            <div class="h400 bg_w br20 wow animate__animated animate__slideInUp"
              :class="index==1||index==2?'mt144':'mb144'"
              style="width: calc( (100% - 6.25vw) / 4 );"
              v-for="(item,index) in trouble" :key="index"
              data-wow-duration="0.6s" data-wow-iteration="1" data-wow-delay="0.2s"
            >
              <div class="w-per100 h-per100 bg_0F9E73-FFFFFF p20">
                <div class="wh70 row a_center nowrap">
                  <img class="wh70" :src="item.thumb" alt="">
                </div>
                <div class="ft20 mt15 text_nowrap">
                  {{ item.title ? item.title : '' }}
                </div>
                <div class="mt20 ft16 lh24 info_color">
                  {{ item.content ? item.content : '' }}
                </div>
              </div>
            </div>
          </div>
          <div style="width: calc( 75% + 1.5625vw );" class="h384 mt-250 mxauto">
            <img class="w-per100" src="https://www.daiweicd.com/commonImg/xian.png" alt="">
          </div>
        </div>

      </div>
    </div>

    <!-- 四大优势 -->
    <div id="product3" class="product3 column a_center w-per100 minh660">
      <div class="w-per62_5 column a_center">
        <div class="c_294A3B ft32 mt50 wow animate__animated animate__zoomIn" data-wow-duration="0.6s" data-wow-iteration="1" data-wow-delay="0.2s">
          四大优势
        </div>

        <div class="w-per100 mt100 mb100">
          <div class="sincereBox h-per100 row j_sb trans_3">
            
          </div>
        </div>

      </div>
    </div>

    <!-- 联系我们 -->
    <div class="w-per100 bg_1B222A minh860">

      <div id="contactUs" class="consultBox w-per100 minh750 bg_w pb50">

        <div class="w-per62_5 column a_center mxauto">

          <div class="c_294A3B ft32 mt100 wow animate__animated animate__zoomIn" data-wow-duration="0.6s" data-wow-iteration="1" data-wow-delay="0.2s">
            联系我们
          </div>

          <div class="w-per100 row j_end mt30 mb80">
            <div class="w500">
              <el-form :inline="true" :model="ruleForm" :rules="rules" class="">
                <el-form-item class="w-per100">
                  <el-input v-model="ruleForm.name" placeholder="姓名">
                    <img slot="prefix" class="wh30 mt_icon" src="https://www.daiweicd.com/commonImg/Light1.png" alt="">
                  </el-input>
                </el-form-item>
                <el-form-item class="w-per100">
                  <el-input v-model="ruleForm.phone" placeholder="联系方式">
                    <img slot="prefix" class="wh30 mt_icon" src="https://www.daiweicd.com/commonImg/Light2.png" alt="">
                  </el-input>
                </el-form-item>
                <el-form-item class="w-per100">
                  <el-input v-model="ruleForm.remarks" placeholder="需求备注">
                    <img slot="prefix" class="wh30 mt_icon" src="https://www.daiweicd.com/commonImg/Light3.png" alt="">
                  </el-input>
                </el-form-item>
              </el-form>
              <div class="w-per100 h60 bg_0F9E73-67CF90 mt50 column flex_center pointer" @click="to_sub">
                <div class="white_color ft20">
                  提 交
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 营销之路 -->
      <div class="w-per62_5 minh220 mt-110 mxauto px50 py40 white_color wow animate__animated animate__zoomIn" 
        style="background-image:url('https://www.daiweicd.com/commonImg/bg_footer.png');background-size: 100% 100%;background-repeat: no-repeat;"
        data-wow-duration="0.6s" data-wow-iteration="1" data-wow-delay="0.2s"
      >
          <div class="ft28 bolder">
            {{contact_us_text.title}}
          </div>
          <div class="ft20 mt25">
            {{contact_us_text.description}}
          </div>
        </div>
    </div>

    <!-- footer -->
    <my-footer :current="current" @onScrollTo="scroll"></my-footer>
  </div>
</template>

<script>
import { getSeoList, productView, otherVal, consultSub } from "@/api/app"
export default {
  metaInfo() {
    return {
      title: '全链营销',
      meta: [
        {
          name: "keywords",
          content: this.PagekeyWords,
        },
        {
          name: "description",
          content: this.PageDescription,
        },
        {
          property: "og:type",
          content: 'website',
        },
        {
          property: "og:title",
          content: this.PageTitle,
        },
        {
          property: "og:description",
          content: this.PageDescription,
        },
        {
          property: "og:site_name",
          content: this.PageTitle,
        },
        {
          property: "og:url",
          content: 'https://www.daiweicd.com/#/product',
        },
        {
          property: "og:image",
          content: 'https://www.daiweicd.com/favicon.ico',
        },
      ],
    };
  },
  data() {
    return {
      PageTitle: "",
      PagekeyWords: "",
      PageDescription: "",
      ruleForm: {
        name: "",//姓名
        phone: "",//联系方式
        remarks: "",
      },
      rules: {
        name: [{required: true, message: "请输入您的姓名", trigger: "blur"}],
        phone: [{required: true, message: "请输入联系方式", trigger: "blur"}],
        remarks: [{required: true, message: "请输入需求备注", trigger: "blur"}],
      },
      contact_us_text:{},//footer
      advantage:[],//四大优势
      trouble:[],//您的烦恼
      list:[],//一站式营销
      otherdata: {},
      // title: '全链营销',
      current: 1,
    }
  },
  mounted() {
    this.fetchSeoList()

    this.fetchProduct();
    this.fetchOtherVal();
  },
  methods: {
    async fetchSeoList() {
      let {data} = await getSeoList()
      this.tit = data.data.val
      // console.log('tt',this.tit);
      this.PageTitle = this.tit.zd_name?this.tit.zd_name:'岱微'
      this.PagekeyWords = this.tit.wz_keyword?this.tit.wz_keyword:'岱微'
      this.PageDescription = this.tit.zd_desc?this.tit.zd_desc:'成都岱微汽车服务有限公司'
    },
    // 提交
    async to_sub() {
      console.log("表单", this.ruleForm);
      if(!this.checkForm()){
        return ;
      }else{
        let {data} = await consultSub({
          name: this.ruleForm.name,
          phone: this.ruleForm.phone,
          remarks: this.ruleForm.remarks,
        })
        if (data.code == 1) {
          this.$message({
            message: "提交成功",
            type: "success",
          })
          this.ruleForm.name = '';
          this.ruleForm.phone = '';
          this.ruleForm.remarks = '';
        } else {
          this.$message.error(data.msg)
        }
      }
    },
    checkForm(){
      if(this.ruleForm.name == ''){
        this.$message.error('请输入您的姓名');
        return false;
      }else if(this.ruleForm.phone == ''){
        this.$message.error('请输入联系方式');
        return false;
      }else if(this.ruleForm.remarks == ''){
        this.$message.error('请输入需求备注');
        return false;
      }else{
        return true;
      }
    },
    handleShow(index,id) {
      // console.log(id,index)
      this.current = index
      if (index == 0) {
        this.$router.push({
          path: "/",
        })
      }
      if (index == 2) {
        this.$router.push({
          path: "/aboutus",
        })
      }
      this.handelUpdate()
      if(id){
        setTimeout(()=>{
          var el = document.getElementById(id)
          this.$nextTick(function () {
            window.scrollTo({
              behavior: "smooth",
              top: el.offsetTop + -88,
            })
          })
        },100)
      }else{
        return
      }
    },
    handelUpdate() {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      })
    },
    scroll({id, offset, num}) {
      // console.log("111")
      if (num == -1) {
        offset = offset || -10
        var el = document.getElementById(id)
        this.$nextTick(function () {
          window.scrollTo({
            behavior: "smooth",
            top: el.offsetTop + offset,
          })
        })
      } else {
        this.handleShow(num,id)
      }
    },
    // 四大优势
    showAdvantage(){
      let that = this;

      // 获取盒子 写子节点
      that.assData(that.advantage,0);

      // 获取这类节点
      let boxList = document.getElementsByClassName("boxs");
      
      for (let i = 0; i < that.advantage.length; i++) {
        // 监听鼠标移入
        boxList[i].onmouseover = function () {
          
          if (this.style.width == '18%') {//移到另一个
            let boxLi = document.getElementsByClassName("boxs");
            console.log('1over',i);
            // 清除
            for (let j = 0; j < boxLi.length; j++) {
              boxLi[j].style.width = '18%';
              boxLi[j].style.backgroundImage = ``;
              boxLi[j].style.backgroundSize = '0% 0%';
              boxLi[j].style.backgroundColor = `#fff`;
              let nomalBox = boxLi[j].getElementsByClassName("nomalBox")[0];
              nomalBox.style.height = '100%';
              let showBox = boxLi[j].getElementsByClassName("showBox")[0];
              showBox.style.height = '0';
            }
            // 加宽
            boxLi[i].style.width = '41.25%';
            boxLi[i].style.backgroundImage = `url("${that.advantage[i].bg_img}")`;
            boxLi[i].style.backgroundSize = '102% 26.4417vw';
            let nomalBox = boxLi[i].getElementsByClassName("nomalBox")[0];
            nomalBox.style.height = '0';
            let showBox = boxLi[i].getElementsByClassName("showBox")[0];
            showBox.style.height = '100%';
          }else {
            // 移出
            console.log('2over',i);
            let boxs = document.getElementsByClassName("boxs");
            if (this.style.width == '41.25%'){
              boxs[i].style.width = '41.25%';
              boxs[i].style.backgroundImage = `url("${that.advantage[i].bg_img}")`;
              boxs[i].style.backgroundSize = '102% 26.4417vw';
              let nomalBox = boxs[i].getElementsByClassName("nomalBox")[0];
              nomalBox.style.height = '0';
              let showBox = boxs[i].getElementsByClassName("showBox")[0];
              showBox.style.height = '100%';
            }else{
              boxs[i].style.width = '18%';
              boxs[i].style.backgroundImage = ``;
              boxs[i].style.backgroundSize = '0% 0%';
              boxs[i].style.backgroundColor = `#fff`;
              let nomalBox = boxs[i].getElementsByClassName("nomalBox")[0];
              nomalBox.style.height = '100%';
              let showBox = boxs[i].getElementsByClassName("showBox")[0];
              showBox.style.height = '0';
            }
          }
        }

        boxList[i].onclick = function () {
          console.log('onclick');
          let boxLi = document.getElementsByClassName("boxs");
          for (let j = 0; j < boxLi.length; j++) {
            boxLi[j].style.width = '18%';
            boxLi[j].style.backgroundImage = ``;
            boxLi[j].style.backgroundSize = '0% 0%';
            boxLi[j].style.backgroundColor = `#fff`;
            let nomalBox = boxLi[j].getElementsByClassName("nomalBox")[0];
            nomalBox.style.height = '100%';
            let showBox = boxLi[j].getElementsByClassName("showBox")[0];
            showBox.style.height = '0';
          }
          boxLi[i].style.width = '41.25%';
          boxLi[i].style.backgroundImage = `url("${that.advantage[i].bg_img}")`;
          boxLi[i].style.backgroundSize = '102% 26.4417vw';
          let nomalBox = boxLi[i].getElementsByClassName("nomalBox")[0];
          nomalBox.style.height = '0';
          let showBox = boxLi[i].getElementsByClassName("showBox")[0];
          showBox.style.height = '100%';
        }
      }
    },
    assData(data,index){
      console.log('assData',index);
      let box = document.getElementsByClassName("sincereBox")[0]
      box.innerHTML = ""
      for(var i = 0; i<data.length;i++ ){
        box.innerHTML += `
          <div class="boxs py40 h400 br10 column a_center j_sb" style="${index==i?'background-image:url('+data[i]['bg_img']+');background-size: 102% 26.4417vw;background-repeat: no-repeat;width:41.25%;':'background-color:#fff;width:18%;'}">
            <div class="nomalBox w-per100 h-per100 column a_center j_sb o-h" style="${index==i?'height:0;':''}">
              <div class="titleBox ft24 text_center">${data[i]["titletwo"]}</div>
              <img class="wh70" src="${data[i]["thumb"] }" alt="">
            </div>
            <div class="showBox w-per100 h-per100 px40 white_color  o-h" style="${index==i?'':'height:0;'}">
              <div class="ft24 text_nowrap">
                ${data[i]["title"]}
              </div>
              <div class="mt20 w-per100 bg_fff-5 h3">
                <div class="bg_w w50 h3"></div>
              </div>
              <div class="mt20 ft18 lh24">
                ${data[i]["content"]}
              </div>
            </div>
          </div>`
      }
    },
    // 其他
    async fetchOtherVal() {
      let {data} = await otherVal()
      this.otherdata = data.data;

      // console.log('other',this.otherdata);

      let productBox1 = document.querySelector('.product1');
      productBox1.style.backgroundImage = `url(${this.otherdata.img_eight})`
      productBox1.style.backgroundSize = "100% 100%"
      productBox1.style.backgroundRepeat = "no-repeat"

      let productBox2 = document.querySelector('.product2');
      productBox2.style.backgroundImage = `url(${this.otherdata.img_nine})`
      productBox2.style.backgroundRepeat = "no-repeat"

      let productBox3 = document.querySelector('.product3');
      productBox3.style.backgroundImage = `url(${this.otherdata.img_eleven})`
      productBox3.style.backgroundSize = "100% 100%"
      productBox3.style.backgroundRepeat = "no-repeat"

      let consultBox = document.querySelector('.consultBox');
      consultBox.style.backgroundImage = `url(${this.otherdata.img_six})`
      consultBox.style.backgroundSize = "100% 100%"
      consultBox.style.backgroundRepeat = "no-repeat"
    },
    async fetchProduct(){
      let {data} = await productView();
      // console.log('fetchProduct',data.data);
      this.contact_us_text = data.data.contact_us_text;
      this.advantage = data.data.advantage;
      this.trouble = data.data.trouble;
      this.list = data.data.list;
      
      this.showAdvantage();
    },
  },
}
</script>

<style scoped lang="scss">
.main{
  width: 100%;
  min-height: 100%;
  // padding-top: 5.2083vw;
  margin: 0px;
  background-color: #fff;
  position: relative;
  overflow-x: hidden;

  .product2{
    background-size: 99% 100%;
    background-position-x:center;
    background-position-y:center;
  }
  @media screen and (min-width: 1250px) and (max-width: 1500px){
    .product2{
      min-height: 46.875vw;
      background-size: 110% 100%;
      background-position-x:center;
      background-position-y:center;
    }
  }
  @media screen and (min-width: 750px) and (max-width: 1250px){
    .product2{
      min-height: 52.0833vw;
      background-size: 125% 112%;
      background-position-x:center;
      background-position-y:center;
      .h400{
        height: 26.0417vw;
      }
    }
  }
  @media screen and (min-width: 450px) and (max-width: 750px){
    .product2{
      min-height: 46.875vw;
      background-size: 125% 120%;
      background-position-x:center;
      background-position-y:center;
      .h400{
        height: 26.0417vw;
      }
    }
  }
  @media screen and (max-width: 450px){
    .product2{
      min-height: 46.875vw;
      background-size: 125% 120%;
      background-position-x:center;
      background-position-y:center;
      .h400{
        height: 36vw;
      }
    }
  }
  
  .sincereBox{
      ::v-deep .boxs{
        margin-left: 1.0417vw;
        width: 18%;
      }
      ::v-deep .boxs:nth-of-type(1){
        margin-left: 0;
        width: 41.25%;
      }
  }

  .consultBox{
    ::v-deep .el-form-item{
      margin: 0;
    }
    ::v-deep .el-form--inline .el-form-item__content{
      width: 100%;
    }
    ::v-deep .el-input__inner.el-input__inner.el-input__inner{
      margin-top: 1.5625vw;
      padding-left: 2.0833vw;
      height: 3.125vw;
      color: #333333;
      background: rgba(255,255,255,0.7);
      border-radius: 0px;
      border: 1px solid #67CF90;
      outline: none;
    }
    .el-form-item__content{
        
    }
    @media screen and (min-width: 1250px) and (max-width: 1500px){
      ::v-deep .el-input__inner.el-input__inner.el-input__inner{
        font-size: 16px;
        padding-left: 2.0833vw;
      }
    }
    @media screen and (min-width: 750px) and (max-width: 1250px){
      ::v-deep .el-input__inner.el-input__inner.el-input__inner{
        font-size: 14px;
        padding-left: 2.2833vw;
      }
    }
    @media screen and (max-width: 750px){
      ::v-deep .el-form-item__content{
        line-height: 20px !important;
      }
      ::v-deep .el-input__inner.el-input__inner.el-input__inner{
        font-size: 6px;
        padding-left: 2.6833vw;
      }
    }
    ::v-deep .el-input__inner::placeholder {
      color:  #666666;
    }
  }

  @media screen and (min-width: 750px) and (max-width: 1250px){
    .w400{
      width: 26.0417vw;
    }
  }
  @media screen and (max-width: 750px){
    .w400{
      width: 20.8333vw;
    }
  }
}
</style>
