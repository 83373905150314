<template>
  <div class="main primary_color">
    <!-- nav -->
    <my-nav :current="current" @onHandleShow="handleShow"></my-nav>

    <!-- banner -->
    <div class="bannerBox h550 relative" @click="scroll({id:'contactUs', offset:-88, num:-1})">
      <el-carousel ref="bannershow" :height="bannerHeight" autoplay arrow="never" :interval="2000">
        <el-carousel-item v-for="(item) in banners" :key="item.id">
          <div class="w-vw100 h550 column flex_center relative">
            <div class="w-per100 h550">
              <img class="w-per100 h550" :src="item.thumb" alt="" />
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>

    <!-- 核心服务 -->
    <div id="index1" class="index1 column a_center w-per100 minh795">
      <div class="w-per62_5 column a_center">

        <div class="c_294A3B ft32 mt100 wow animate__animated animate__zoomIn" data-wow-duration="0.6s" data-wow-iteration="1" data-wow-delay="0.2s">
          我们的核心服务
        </div>

        <div class="mt60 row">
          <div class="servicesNav ft20 pointer" :class="services_index==index?'c_0F9E73':'primary_color'" v-for="(item,index) in services_nav" :key="index" @click="changeIndex(index)">{{ item }}</div>
        </div>

        <div class="w-per100 h400 mt60 row j_sb a_center white_color">
          <div class="h400 br10 relative wow animate__animated animate__zoomIn" :class="services_index<2?'w-per24':'w-per32'" v-for="(item,index) in core_services" :key="index" data-wow-duration="0.6s" data-wow-iteration="1" :data-wow-delay="0.2 * (index+1)+'s'">
            <img class="w-per100 h400 br10" :src="item.thumb" alt="">
            <div class="w-per100 h400 absolute top0 left0 zIndex10 p25 column j_end">
              <div class="bolder ft48">{{ item.title?item.title:'' }}</div>
              <div class="ft24 lh36" v-for=" (it,ind) in item.englishtitle ? item.englishtitle.split(' ') : [] " :key="'text-'+ind">{{ it }}</div>
            </div>
          </div>
        </div>

      </div>
    </div>

    <!-- 从咨询到落地方案内容资源运营全解决 -->
    <div id="index2" class="index2 column a_center w-per100 minh705">
      <div class="w-per62_5 column a_center">

        <div class="c_294A3B ft32 mt110 wow animate__animated animate__zoomIn" data-wow-duration="0.6s" data-wow-iteration="1" data-wow-delay="0.2s">
          从咨询到落地方案内容资源运营全解决
        </div>

        <div class="w-per100 mt70 row wrap" :class="landing.length>4?'j_sb':''">
          <div class="w-per19 h130 br10 relative row flex_center mt50 wow animate__animated animate__fadeInDown"
            :class="landing.length>4?'':'mr10'"
            v-for="(item,index) in landing" :key="index"
            :style="'background-image:url('+item.background+');background-size: 100% 100%;background-repeat: no-repeat;'"
            data-wow-duration="0.6s" data-wow-iteration="1" :data-wow-delay="0.2 * ((index % 5)+1)+'s'"
          >
            <div class="wh70 row a_center nowrap">
              <img class="wh70" :src="item.thumb ? item.thumb : ''" alt="">
            </div>
            <div class="ml20 ft20">
              {{ item.title ? item.title : '' }}
            </div>
            <div class="wh30 absolute left-per50 trX-per50 bottom-40" v-if="index<5">
              <img class="wh30" src="https://www.daiweicd.com/commonImg/icon_jiantou_xia.png" alt="">
            </div>
          </div>
        </div>

      </div>
    </div>
    
    <!-- 多场景下全覆盖的用户流动量 -->
    <div id="index3" class="column a_center w-per100">
      <div class="c_294A3B ft32 mt45 wow animate__animated animate__zoomIn" data-wow-duration="0.6s" data-wow-iteration="1" data-wow-delay="0.2s">
        多场景下全覆盖的用户流动量
      </div>
      <!-- 线下场景 -->
      <div class="outLine column a_center w-per100 minh900">
        <div class="w-per62_5 column a_center">

          <div class="primary_color ft20 mt60 wow animate__animated animate__heartBeat" data-wow-duration="0.6s" data-wow-iteration="1" data-wow-delay="0.2s">
            线下场景
          </div>

          <div class="w-per100 mt60 white_color ft30">
            <div class="row j_sb w-per100 h230">
              <div class="row flex_center h230 wow animate__animated animate__flipInX"
                :class="index==0?'w-per39':'w-per59'"
                v-for="(item,index) in offline_scene.slice(0,2)" :key="index"
                :style="'background-image:url('+(item?item.thumb:'')+');background-size: 100% 100%;background-repeat: no-repeat;'"
                data-wow-duration="0.6s" data-wow-iteration="1" :data-wow-delay="0.1 * (index+1)+'s'"
              >
                {{ item.title?item.title:'' }}
              </div>
            </div>
            <div class="row j_sb w-per100 mt20">
              <div class="w-per39 h450 row flex_center wow animate__animated animate__flipInX"
                :style="'background-image:url('+(offline_scene[2]?offline_scene[2].thumb:'')+');background-size: 100% 100%;background-repeat: no-repeat;'"
                data-wow-duration="0.6s" data-wow-iteration="1" data-wow-delay="0.3s"
              >
                {{ offline_scene[2]?offline_scene[2].title:'' }}
              </div>
              <div class="w-per59 h450">
                <div class="w-per100 h215 row j_sb">
                  <div class="w-per49 h215 row flex_center wow animate__animated animate__flipInX"
                    v-for="(item,index) in offline_scene.slice(3,5)" :key="index"
                    :style="'background-image:url('+(item?item.thumb:'')+');background-size: 100% 100%;background-repeat: no-repeat;'"
                    data-wow-duration="0.6s" data-wow-iteration="1" :data-wow-delay="0.1 * (index+4)+'s'"
                  >
                    {{ item.title?item.title:'' }}
                  </div>
                </div>
                <div class="w-per100 h215 row flex_center mt20 wow animate__animated animate__flipInX" 
                  :style="'background-image:url('+(offline_scene[5]?offline_scene[5].thumb:'')+');background-size: 100% 100%;background-repeat: no-repeat;'"
                  data-wow-duration="0.6s" data-wow-iteration="1" data-wow-delay="0.5s"
                >
                  {{ offline_scene[5]?offline_scene[5].title:''}}
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>

      <!-- 线上场景 -->
      <div class="onLine column a_center w-per100 minh800 mt-40">
        <div class="w-per62_5 column a_center">

          <div class="primary_color ft20 mt100 wow animate__animated animate__heartBeat" data-wow-duration="0.6s" data-wow-iteration="1" data-wow-delay="0.2s">
            线上场景
          </div>

          <div class="w-per100 row j_sb mt60 mb110">
            <div class="w-per16 column a_center"
              :class="index%2?'mt100':''"
              v-for="(item,index) in online_scene" :key="index"
            >
              <div class="w-per100 h425 row a_center nowrap wow animate__animated animate__zoomIn" data-wow-duration="0.6s" data-wow-iteration="1" :data-wow-delay="0.2 * (index+1)+'s'">
                <img class="w-per100 h-per100" :src="item ? item.thumb : ''" alt="">
              </div>
              <div class="mt30 show_color ft20">{{ item.title?item.title:'' }}</div>
            </div>
          </div>
          
        </div>
      </div>

    </div>

    <!-- 真诚地服务客户 -->
    <div id="index4" class="index4 column a_center w-per100 minh910 mt-100 bg_w">

      <div class="c_294A3B ft32 mt180 wow animate__animated animate__zoomIn" data-wow-duration="0.6s" data-wow-iteration="1" data-wow-delay="0.2s">
        真诚地服务客户
      </div>

      <div class="w-per62_5 h800 mt54 mb140 column a_center relative">

        <div class="w-per100 h-per100 o-h">
          <div class="sincereBox h-per100 column wrap trans_3">
            
          </div>
        </div>
        
        <div class="prevBtn wh40 row a_center nowrap absolute top-per50 trY-per50 left-50 pointer"
          v-if="sincere_page==1" @click="toPrev">
          <img class="wh40" src="https://www.daiweicd.com/commonImg/icon_zuojiantou.png" alt="">
        </div>
        <div class="nextBtn wh40 row a_center nowrap absolute top-per50 trY-per50 right-50 pointer"
          v-if="sincere_page==0" @click="toNext">
          <img class="wh40" src="https://www.daiweicd.com/commonImg/icon_youjiantou.png" alt="">
        </div>

      </div>
      
    </div>

    <!-- 联系我们 -->
    <div id="contactUs" class="consultBox column a_center w-per100 minh696">
      <div class="w-per62_5 column a_center">
        <div class="primary_color ft32 mt54 wow animate__animated animate__zoomIn" data-wow-duration="0.6s" data-wow-iteration="1" data-wow-delay="0.2s">
          联系我们
        </div>

        <div class="w-per100 row j_end mt90 mb80">
          <div class="w500">
            <el-form :inline="true" :model="ruleForm" :rules="rules" class="">
              <el-form-item class="w-per100">
                <el-input v-model="ruleForm.name" placeholder="姓名">
                  <img slot="prefix" class="wh30 mt_icon" src="https://www.daiweicd.com/commonImg/Light1.png" alt="">
                </el-input>
              </el-form-item>
              <el-form-item class="w-per100">
                <el-input v-model="ruleForm.phone" placeholder="联系方式">
                  <img slot="prefix" class="wh30 mt_icon" src="https://www.daiweicd.com/commonImg/Light2.png" alt="">
                </el-input>
              </el-form-item>
              <el-form-item class="w-per100">
                <el-input v-model="ruleForm.remarks" placeholder="需求备注">
                  <img slot="prefix" class="wh30 mt_icon" src="https://www.daiweicd.com/commonImg/Light3.png" alt="">
                </el-input>
              </el-form-item>
            </el-form>
            <div class="w-per100 h60 bg_0F9E73-67CF90 mt50 column flex_center pointer" @click="to_sub">
              <div class="white_color ft20">
                提 交
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>

    <!-- footer -->
    <my-footer :current="current" @onScrollTo="scroll"></my-footer>

  </div>
</template>

<script>
import { getSeoList, otherVal, getIndex, consultSub } from "@/api/app"

export default {
  metaInfo() {
    return {
      title: '岱微',
      meta: [
        {
          name: "keywords",
          content: this.PagekeyWords,
        },
        {
          name: "description",
          content: this.PageDescription,
        },
        {
          property: "og:type",
          content: 'website',
        },
        {
          property: "og:title",
          content: this.PageTitle,
        },
        {
          property: "og:description",
          content: this.PageDescription,
        },
        {
          property: "og:site_name",
          content: this.PageTitle,
        },
        {
          property: "og:url",
          content: 'https://www.daiweicd.com/#/',
        },
        {
          property: "og:image",
          content: 'https://www.daiweicd.com/favicon.ico',
        },
      ],
    };
  },
  data() {
    return {
      PageTitle: "",
      PagekeyWords: "",
      PageDescription: "",
      ruleForm: {
        name: "",//姓名
        phone: "",//联系方式
        remarks: "",
      },
      rules: {
        name: [{required: true, message: "请输入您的姓名", trigger: "blur"}],
        phone: [{required: true, message: "请输入联系方式", trigger: "blur"}],
        remarks: [{required: true, message: "请输入需求备注", trigger: "blur"}],
      },
      sincere_page:0,
      sincere:[],//真诚地服务客户
      online_scene:[],//线上
      offline_scene:[],//线下
      landing:[],//从咨询到落地
      core_services:[],//核心服务
      services_index:0,
      services_nav:['个性化推广方案','多元化推广渠道','实施数据分析与优化'],
      banners: [],
      current: 0,
      bannerHeight: "28.6458vw",
      otherdata:{},
    }
  },
  mounted() {
    this.fetchSeoList()

    this.current = 0
    this.fetchIndex();
  },
  methods: {
    async fetchSeoList() {
      let {data} = await getSeoList()
      this.tit = data.data.val
      // console.log('tt',this.tit);
      this.PageTitle = this.tit.zd_name?this.tit.zd_name:'岱微'
      this.PagekeyWords = this.tit.wz_keyword?this.tit.wz_keyword:'岱微'
      this.PageDescription = this.tit.zd_desc?this.tit.zd_desc:'成都服务有限公司'
    },
    // 提交
    async to_sub() {
      // console.log("表单", this.ruleForm);
      if(!this.checkForm()){
        return ;
      }else{
        let {data} = await consultSub({
          name: this.ruleForm.name,
          phone: this.ruleForm.phone,
          remarks: this.ruleForm.remarks,
        })
        if (data.code == 1) {
          this.$message({
            message: "提交成功",
            type: "success",
          })
          this.ruleForm.name = '';
          this.ruleForm.phone = '';
          this.ruleForm.remarks = '';
        } else {
          this.$message.error(data.msg)
        }
      }
    },
    checkForm(){
      if(this.ruleForm.name == ''){
        this.$message.error('请输入您的姓名');
        return false;
      }else if(this.ruleForm.phone == ''){
        this.$message.error('请输入联系方式');
        return false;
      }else if(this.ruleForm.remarks == ''){
        this.$message.error('请输入需求备注');
        return false;
      }else{
        return true;
      }
    },
    // 上一页
    toPrev() {
      this.sincere_page -= 1;
      let windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
      let box = document.getElementsByClassName("sincereBox")[0]
      box.style.marginLeft = '0px';
      let boxList = document.getElementsByClassName("box1");
      if(windowWidth <= 750){
        boxList[3].style.width = '26.5vw';
        boxList[4].style.width = '26.5vw';
      }else{
        boxList[3].style.width = '21vw';
        boxList[4].style.width = '21vw';
      }
    },
    // 下一页
    toNext(){
      this.sincere_page += 1;
      let windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
      let box = document.getElementsByClassName("sincereBox")[0]
      if(windowWidth <= 750){
        box.style.marginLeft = '-53.5vw';
      }else{
        box.style.marginLeft = '-39.5vw';
      }
      let boxList = document.getElementsByClassName("box1");
      if(windowWidth <= 750){
        boxList[3].style.width = '36vw';
        boxList[4].style.width = '36vw';
      }else{
        boxList[3].style.width = '29vw';
        boxList[4].style.width = '29vw';
      }
    },
    // 真诚地服务客户
    showSincere(){
      let that = this;
      
      // 获取盒子 写子节点
      that.assData(that.sincere,0);

      // 获取这类节点
      let boxList = document.getElementsByClassName("box1");

      for (let i = 0; i < this.sincere.length; i++) {
        // 监听鼠标移入
        boxList[i].onmouseover = function () {
          //移到另一个
          if (this.style.backgroundImage == `url("${that.sincere[i].img2}")`) {
            console.log('移入',i);
            let boxLi = document.getElementsByClassName("box1");
            for (let j = 0; j < boxLi.length; j++){
              boxLi[j].style.backgroundImage = `url("${that.sincere[i].img1}")`;
              let img = boxLi[j].getElementsByTagName("img")[0];
              img.src = that.sincere[j].thumb;
            }
            boxLi[i].style.backgroundImage = `url("${that.sincere[i].img2}")`;
            let img = boxLi[i].getElementsByTagName("img")[0];
            img.src = that.sincere[i].img3;
          }else {
            // 移出到外面了
            console.log('移出',i);
            let boxs = document.getElementsByClassName("box1");
            if(this.style.backgroundImage == `url('${that.sincere[i].img2}')`){
              // 保持宽
              boxs[i].style.backgroundImage = `url('${that.sincere[i].img1}')`;
              let img =boxs[i].getElementsByTagName("img")[0];
              img.src = that.sincere[i].thumb;
            }else{
              boxs[i].style.backgroundImage = `url('${that.sincere[i].img2}')`;
              let img =boxs[i].getElementsByTagName("img")[0];
              img.src = that.sincere[i].img3;
            }
          }
        }
        boxList[i].onmouseout = function () {
          let boxs = document.getElementsByClassName("box1");

          if (this.style.backgroundImage == `url("${that.sincere[i].img2}")`){
            console.log('onmouseout',i);
            
            boxs[i].style.backgroundImage = `url('${that.sincere[i].img1}')`;
            let img =boxs[i].getElementsByTagName("img")[0];
            img.src = that.sincere[i].thumb;

            // let okk = [];
            // for (let k = 0; k < boxs.length; k++){
            //   if(boxs[k].backgroundImage == `url("${that.sincere[k].img1}")`){
            //     okk.push(false)
            //   }
            // }
            // if(okk.every(it=>it==false)){
            //   boxs[0].style.backgroundImage = `url("${that.sincere[0].img2}")`;
            //   let img = boxs[0].getElementsByTagName("img")[0];
            //   img.src = that.sincere[0].img3;
            // }
          }else{
            console.log('123');
          }
        }
        boxList[i].onclick = function () {
          console.log('onclick');
            let boxLi = document.getElementsByClassName("box1");
            for (let j = 0; j < boxLi.length; j++){
              boxLi[j].style.backgroundImage = `url("${that.sincere[i].img1}")`;
              let img = boxLi[j].getElementsByTagName("img")[0];
              img.src = that.sincere[j].thumb;
            }
            boxLi[i].style.backgroundImage = `url("${that.sincere[i].img2}")`;
            let img = boxLi[i].getElementsByTagName("img")[0];
            img.src = that.sincere[i].img3;

            boxList[i].onmouseout = function (){}

          // if (this.style.backgroundImage == `url("${that.sincere[i].img2}")`) {
          //   let boxLi = document.getElementsByClassName("box1");
          //   for (let j = 0; j < boxLi.length; j++){
          //     boxLi[j].style.backgroundImage = `url("${that.sincere[i].img1}")`;
          //     let img = boxLi[j].getElementsByTagName("img")[0];
          //     img.src = that.sincere[j].thumb;
          //   }
          //   boxLi[i].style.backgroundImage = `url("${that.sincere[i].img2}")`;
          //   let img = boxLi[i].getElementsByTagName("img")[0];
          //   img.src = that.sincere[i].img3;
          // }else {
          //   let boxs = document.getElementsByClassName("box1");
          //   if(this.style.backgroundImage == `url('${that.sincere[i].img2}')`){
          //     // 保持宽
          //     boxs[i].style.backgroundImage = `url('${that.sincere[i].img1}')`;
          //     let img =boxs[i].getElementsByTagName("img")[0];
          //     img.src = that.sincere[i].thumb;
          //   }else{
          //     boxs[i].style.backgroundImage = `url('${that.sincere[i].img2}')`;
          //     let img =boxs[i].getElementsByTagName("img")[0];
          //     img.src = that.sincere[i].img3;
          //   }
          // }


        }
      }
    },
    assData(data,index){
      console.log('assData',index);
      let box = document.getElementsByClassName("sincereBox")[0]
      box.innerHTML = "";
      for(var i = 0; i<data.length;i++ ){
        let arr = data[i]["text1"].split('；');
        let output = arr.map((item,index) => {
          return index != arr.length - 1? `<li>${item}；</li>` : `<li>${item}</li>`
        }).join('');
        box.innerHTML += `
          <div class="box1 px20 py40 mr30 mt30" style="background-image:url('${index==i?data[i]['img2']:data[i]['img1']}');background-size: 100% 100%;background-repeat: no-repeat;">
            <img class="wh50" src="${index==i?data[i]['img3']:data[i]["thumb"]}" alt="">
            <div class="titleBox ft20 mt20">${data[i]["title"]}</div>
            <div class="textBox ft14 mt15 lh24 info_color">${output}</div>
          </div>`
        
      }
    },
    //我们的核心服务 切换
    async changeIndex(ind) {
      this.services_index = ind;
      this.core_services = [];
      let {data} = await getIndex(
        {
          type_on:this.services_index + 1,
        }
      )
      this.core_services = data.data.our_serve.core_services;
    },
    // 导航栏切换
    handleShow(index,id) {
      // console.log(index)
      this.current = index
      if (index == 1) {
        this.$router.push({
          path: "/product",
        })
        this.handelUpdate()
      }
      if (index == 2) {
        this.$router.push({
          path: "/aboutus",
        })
      }
      this.handelUpdate()
      if(id){
        setTimeout(()=>{
          var el = document.getElementById(id)
          this.$nextTick(function () {
            window.scrollTo({
              behavior: "smooth",
              top: el.offsetTop + -88,
            })
          })
        },100)
      }else{
        return
      }
    },
    // 回顶部
    handelUpdate() {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      })
    },
    // 滚动到某版块
    scroll({id, offset, num}) {
      if (num == -1) {
        offset = offset || -10
        var el = document.getElementById(id)
        this.$nextTick(function () {
          window.scrollTo({
            behavior: "smooth",
            top: el.offsetTop + offset,
          })
        })
      } else {
        this.handleShow(num,id)
      }
    },
    // 首页
    async fetchIndex() {
      let {data} = await getIndex(
        {
          type_on:this.services_index + 1,
        }
      )

      // console.log("index", data.data)

      this.banners = data.data.banner_list;
      this.core_services = data.data.our_serve.core_services;
      this.landing = data.data.our_serve.landing;//从咨询到落地
      this.offline_scene = data.data.our_serve.offline_scene;//线下
      this.online_scene = data.data.our_serve.online_scene;//线上
      this.sincere = data.data.our_serve.sincere;//真诚地服务客户
     
      this.fetchOtherVal();
      this.showSincere();
    },
    // 其他
    async fetchOtherVal() {
      let {data} = await otherVal()
      this.otherdata = data.data;

      // console.log('other',this.otherdata);
      
      let index1 = document.querySelector('.index1');
      index1.style.backgroundImage = `url(${this.otherdata.img_one})`
      index1.style.backgroundSize = "100% 100%"
      index1.style.backgroundRepeat = "no-repeat"
      let index2 = document.querySelector('.index2');
      index2.style.backgroundImage = `url(${this.otherdata.img_two})`
      index2.style.backgroundSize = "100% 100%"
      index2.style.backgroundRepeat = "no-repeat"
      let outLine = document.querySelector('.outLine');
      outLine.style.backgroundImage = `url(${this.otherdata.img_three})`
      outLine.style.backgroundSize = "100% 100%"
      outLine.style.backgroundRepeat = "no-repeat"
      let onLine = document.querySelector('.onLine');
      onLine.style.backgroundImage = `url(${this.otherdata.img_four})`
      onLine.style.backgroundSize = "100% 43.3854vw"
      onLine.style.backgroundRepeat = "no-repeat"
      let index4 = document.querySelector('.index4');
      index4.style.backgroundImage = `url(${this.otherdata.img_five})`
      index4.style.backgroundSize = "100% 100%"
      index4.style.backgroundRepeat = "no-repeat"
      let consultBox = document.querySelector('.consultBox');
      consultBox.style.backgroundImage = `url(${this.otherdata.img_six})`
      consultBox.style.backgroundSize = "100% 100%"
      consultBox.style.backgroundRepeat = "no-repeat"
    },
  },
}
</script>

<style scoped lang="scss">
.main {
  width: 100%;
  min-height: 100%;
  margin: 0px;
  background-color: #fff;
  overflow-x: hidden;

  .bannerBox {
    width: 100vw;
  }

  .index1{
    .servicesNav:not(:first-of-type){
      margin-left: 3.6458vw;
    }
    .servicesNav:hover{
      color: #0F9E73;
    }
  }

  .index4{
    .h800{

    }
    @media screen and (min-width: 1250px) and (max-width: 1500px){
      .w-per62_5{width: 62.5%;}
    }
    @media screen and (min-width: 750px) and (max-width: 1250px){
      .w-per62_5{width: 63%;}
    }
    @media screen and (max-width: 750px){
      .w-per62_5{width: 80%;}
    }
    .sincereBox{
      margin-left: 0vw;
      ::v-deep .box1{
        height: 19.2708vw;
      }
      ::v-deep .box1:nth-of-type(1){
        width: 16.5vw;
        height: 100%;
      }
      ::v-deep .box1:nth-of-type(2){
        width: 21vw;
      }
      ::v-deep .box1:nth-of-type(3){
        width: 21vw;
      }
      ::v-deep .box1:nth-of-type(4){
        width: 21vw;
      }
      ::v-deep .box1:nth-of-type(5){
        width: 21vw;
      }
      ::v-deep .box1:nth-of-type(6){
        width: 29vw;
      }
      ::v-deep .box1:nth-of-type(7){
        width: 29vw;
      }
    }
    @media screen and (min-width: 750px) and (max-width: 900px){
        .h800{
          height: 46.875vw;
        }
        ::v-deep .box1{
          height: 21.875vw !important;
        }
        ::v-deep .box1:nth-of-type(1){
          height: 100% !important;
        }
    }
    @media screen and (min-width: 500px)  and (max-width: 750px){
      .h800{
        height: 50vw;
      }
      ::v-deep .box1{
        height: 23.4375vw !important;
      }
      ::v-deep .box1:nth-of-type(1){
        height: 100% !important;
      }
      .sincereBox{
        margin-left: 0vw;
        ::v-deep .box1{
          height: 19.2708vw;
        }
        ::v-deep .box1:nth-of-type(1){
          width: 22.5vw;
          height: 100%;
        }
        ::v-deep .box1:nth-of-type(2){
          width: 26.5vw;
        }
        ::v-deep .box1:nth-of-type(3){
          width: 26.5vw;
        }
        ::v-deep .box1:nth-of-type(4){
          width: 26.5vw;
        }
        ::v-deep .box1:nth-of-type(5){
          width: 26.5vw;
        }
        ::v-deep .box1:nth-of-type(6){
          width: 36vw;
        }
        ::v-deep .box1:nth-of-type(7){
          width: 36vw;
        }
      }
    }
    @media screen and (max-width: 500px){
      .h800{
        height: 70vw;
      }
      ::v-deep .box1{
        height: 33.4375vw !important;
      }
      ::v-deep .box1:nth-of-type(1){
        height: 100% !important;
      }
      .sincereBox{
        margin-left: 0vw;
        ::v-deep .box1{
          height: 19.2708vw;
        }
        ::v-deep .box1:nth-of-type(1){
          width: 22.25vw;
          height: 100%;
        }
        ::v-deep .box1:nth-of-type(2){
          width: 26.5vw;
        }
        ::v-deep .box1:nth-of-type(3){
          width: 26.5vw;
        }
        ::v-deep .box1:nth-of-type(4){
          width: 26.5vw;
        }
        ::v-deep .box1:nth-of-type(5){
          width: 26.5vw;
        }
        ::v-deep .box1:nth-of-type(6){
          width: 36vw;
        }
        ::v-deep .box1:nth-of-type(7){
          width: 36vw;
        }
      }
    }
  }

  .consultBox{
    ::v-deep .el-form-item{
      margin: 0;
    }
    ::v-deep .el-form--inline .el-form-item__content{
      width: 100%;
    }
    ::v-deep .el-input__inner.el-input__inner.el-input__inner{
      margin-top: 1.5625vw;
      padding-left: 2.0833vw;
      height: 3.125vw;
      color: #333333;
      background: rgba(255,255,255,0.7);
      border-radius: 0px;
      border: 1px solid #67CF90;
      outline: none;
    }
    .el-form-item__content{
        
    }
    @media screen and (min-width: 1250px) and (max-width: 1500px){
      ::v-deep .el-input__inner.el-input__inner.el-input__inner{
        font-size: 16px;
        padding-left: 2.0833vw;
      }
    }
    @media screen and (min-width: 750px) and (max-width: 1250px){
      ::v-deep .el-input__inner.el-input__inner.el-input__inner{
        font-size: 14px;
        padding-left: 2.2833vw;
      }
    }
    @media screen and (max-width: 750px){
      ::v-deep .el-form-item__content{
        line-height: 20px !important;
      }
      ::v-deep .el-input__inner.el-input__inner.el-input__inner{
        font-size: 6px;
        padding-left: 2.6833vw;
      }
    }
    ::v-deep .el-input__inner::placeholder {
      color:  #666666;
    }
  }

}
/* banner指示器 */
::v-deep .el-carousel__indicator--horizontal .el-carousel__button.el-carousel__button {
  // width: 12px !important;
  // height: 12px !important;
  // border-radius: 50%;
  width: 2.0833vw !important;
  height: 3px !important;
  background: #0F9E73;
  border-radius: 0px 0px 0px 0px;
}
</style>
