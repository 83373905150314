<template>
  <div class="w-per100 column flex_center footerBox bg_1B222A white_color ft16">
    <div class="w-per62_5 footer row j_sb a_center">
      <div class="flex1 row j_sb my30">
        <ul
          class="column"
          v-for="(item, index) in list"
          :key="item.id"
        >
          <li class="ft16 c_0F9E73 pointer mt20" @click="scrollTo(index,item.catname)">{{ item.catname }}</li>
          <li class="ft15 pointer mt20" v-for="(ite,ind) in item.child" :key="ind" @click="scrollTo(index,ite.catname,ind)">{{ ite.catname }}</li>
        </ul>
      </div>
      <div class="ml200 my18 column a_center">
        <img class="w140 h60" :src="logo" alt="" />
      </div>
    </div>
    <div class="ft13 mt20 input_color row flex_center">
      <a href="https://beian.miit.gov.cn" target="_blank">{{ icp }}</a>
    </div>
  </div>
</template>
<script>
import {bottomNav, otherVal} from "@/api/app"

export default {
  data() {
    return {
      list: [],
      logo: "",
      icp: "",
    }
  },
  props: {
    current: Number,
  },
  mounted() {
    this.list = [
        {
          catname:'首页',
          child:[
          {
            catname:'核心服务'
          },
          {
            catname:'咨询到落地方案内容资源运营全解决'
          },
          {
            catname:'多场景下全覆盖的用户流动量'
          },
          {
            catname:'真诚地服务客户'
          },
          {
            catname:'联系我们'
          },
          ]
        },
        {
          catname:'全链营销',
          child:[
            {
              catname:'一站式运营'
            },
            {
              catname:'您的烦恼'
            },
            {
              catname:'四大优势'
            },
            {
            catname:'联系我们'
          },
          ]
        },
        {
          catname:'关于我们',
          child:[
            {
              catname:'公司介绍'
            },
            {
              catname:'合作咨询'
            },
          ]
        },
      ]
    this.fetchOtherVal()
  },
  methods: {
    scrollTo(num,text,li) {
      console.log(num,text,li)
      let data = {}
      if (num == this.current) {// 本页面滚动
        if (text == "首页" && li==undefined) {
          data.num = 0
        } else if (text == "全链营销" && li==undefined) {
          data.num = 1
        } else if (text == "关于我们" && li==undefined) {
          data.num = 2
        } else {
          data.num = -1
          if (text == "联系我们") {
            data.id = "contactUs"
          }
          // num=0
          if (text == "核心服务") {
            data.id = "index1"
          }
          if (text == "咨询到落地方案内容资源运营全解决") {
            data.id = "index2"
          }
          if (text == "多场景下全覆盖的用户流动量") {
            data.id = "index3"
          }
          if (text == "真诚地服务客户") {
            data.id = "index4"
          }
          // num=1
          if (text == "一站式运营") {
            data.id = 'product1'
          }
          if (text == "您的烦恼") {
            data.id = 'product2'
          }
          if (text == "四大优势") {
            data.id = 'product3'
          }
          // num=2
          if (text == "公司介绍") {
            data.id = "aboutus1"
          }
          if (text == "合作咨询") {
            data.id = "aboutus2"
          }
        }
        data.offset = -88
      } else {
        // 跳转路由
        data.num = num;
        data.offset = -88
        // num=0
        switch (num){
          case 0:
            if (text == "核心服务") {
              data.id = "index1"
            }
            if (text == "咨询到落地方案内容资源运营全解决") {
              data.id = "index2"
            }
            if (text == "多场景下全覆盖的用户流动量") {
              data.id = "index3"
            }
            if (text == "真诚地服务客户") {
              data.id = "index4"
            }
            if (text == "联系我们") {
              data.id = "contactUs"
            }
            break;
          case 1:
            if (text == "一站式运营") {
              data.id = 'product1'
            }
            if (text == "您的烦恼") {
              data.id = 'product2'
            }
            if (text == "四大优势") {
              data.id = 'product3'
            }
            if (text == "联系我们") {
              data.id = "contactUs"
            }
            break;
          case 2:
            if (text == "公司介绍") {
              data.id = "aboutus1"
            }
            if (text == "合作咨询") {
              data.id = "aboutus2"
            }
            break;
          default:
            break;
        }
      }
      console.log("data", data)
      this.$emit("onScrollTo", data)
    },
    async fetchOtherVal() {
      let {data} = await otherVal()
      this.logo = data.data.bottom_logo;
      this.icp = data.data.icp;
    },
  },
}
</script>
<style lang="scss">
.footerBox {
  // height: 280px;
  padding: 10px 0px 20px;
  .footer {
    padding: 0 5.2083vw;
    border-bottom: 1px solid rgba(244,244,244,0.5);
  }
  a{
      color: #999999;
      text-decoration: none;
    }
    a:link{
      color:#999999;
    }
    a:visited{
      color: #999999;
    }
    a:hover{
      color: #999999;
    }
    a:active{
      color: #999999;
    }
}
</style>
