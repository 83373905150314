<template>
  <div class="w-per100 h500 column flex_center white_color" :style="{backgroundImage: 'url('+ bg +')',backgroundSize: '100% 100%'}">
    <!-- <div class="ft48 bolder mt20">{{ title }}</div> -->
  </div>
</template>
<script>
import { otherVal } from '@/api/app'
export default {
  data() {
    return {
      bg:''
    }
  },
  props:{
    // title:String,
    current: Number,
  },
  mounted() {
    this.fetchOtherVal()
  },
  methods: {
    async fetchOtherVal() {
      let {data} = await otherVal()
      if(this.current == 1){
        this.bg = data.data.img_seven
      }
      if(this.current == 2){
        this.bg = data.data.img_thirteen
      }
    },
  },
}
</script>
<style lang="scss">

</style>
