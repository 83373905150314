<template>
  <div class="navbar row flex_center bg_fff-4">
    <div class="w-per62_5 row j_sb a_center">
      <img
      class="logoimg1"
      :src="logo"
      alt=""
      @click="handleShow(0)"
    />

    <ul class="row">
      <li
        class="row flex_center ft18 primary_color relative px50 pointer"
        v-for="(item, index) in navList"
        :key="index"
        @click="handleShow(index)"
      >
        <span>{{ item.catname }}</span>
        <div :class="current==index?'absolute bottom0 left-per50 trX-per50 line-nav':'h0'" class="mb10"></div>
      </li>
    </ul>
    </div>
  </div>
</template>
<script>
import {getIndex, otherVal} from "@/api/app"
export default {
  data() {
    return {
      navList: [],
      logo: "",
    }
  },
  props: {
    current: Number,
  },
  mounted() {
    this.fetchIndex();
    this.fetchOtherVal()
  },
  methods: {
    handleShow(index) {
      this.$emit("onHandleShow", index)
    },
    // 首页
    async fetchIndex() {
      let {data} = await getIndex(
        {
          type_on:1,
        }
      )
      // console.log("index", data.data)
      this.navList = data.data.nav_list
    },
    async fetchOtherVal() {
      let {data} = await otherVal()
      this.logo = data.data.logo
    },
  },
}
</script>
<style lang="scss">
.navbar {
  width: 100vw;
  height: 3.6458vw;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 666;
  .logoimg1 {
    // width: 8.3333vw;
    // height: 2.0833vw;
    width: 5.5208vw;
    height: 2.0833vw;
  }
  >div{
    > ul {
      // height: 5.2083vw;
      // height: 4.5833vw;
      height: 3.90625vw;
      > li {
        height: 100%;
        transition: all 0.3s linear;
        .line-nav{
          
        }
      }
      > li:hover{
        .h0{
          width: 1.5625vw;
          height: 0.2083vw;
          background: #0F9E73;
          border-radius: 2px 2px 2px 2px;
          position: absolute;
          bottom: 0;
          left:50%;
          transform: translateX(-50%);
        }
      }
    }
  }
}
</style>
