<template>
  <div class="main primary_color">
    <!-- nav -->
    <my-nav
      :current="current"
      @onHandleShow="handleShow"
    ></my-nav>

    <!-- 关于我们 -->
    <son-banner :current="current"></son-banner>

    <!-- 公司介绍 -->
    <!-- <div id="aboutus1" class="aboutus1 column a_center w-per100 minh750">
      <div class="w-per62_5">
        <div class="mt100 row a_center">
          <div class="wh60 row flex_center nowrap">
            <img class="wh60" src="https://www.daiweicd.com/commonImg/icon_gongsijianjie.png" alt="">
          </div>
          <div class="ft32 ml-40">
            公司介绍
          </div>
        </div>
        <div class="w-per100 row j_sb a_center mt66">
          <div class="w-per60">
            <div class="ft24">
              {{ about.title }}
            </div>
            <div class="mt30 ft18 richText1">
              
            </div>
            <div class="row mt50">
              <div class="wh120 column flex_center shadow_4" :class="index>0?'ml20':''" v-for="(item,index) in small_icon" :key="index">
                <div class="wh50 row flex_center nowrap">
                  <img class="wh50" :src="item.thumb" alt="">
                </div>
                <div class="ft18 mt10">
                  {{ item.title }}
                </div>
              </div>
            </div>
          </div>
          <div class="w400 h450 row flex_center nowrap wow animate__animated animate__zoomIn" data-wow-duration="0.6s" data-wow-iteration="1" data-wow-delay="0.2s">
            <img class="w-per100 h-per100" :src="right_img" alt="">
          </div>
        </div>
      </div>
    </div> -->
    <!-- <div class="aboutus2 column a_center w-per100 minh860 mt45">
      <div class="w-per62_5">
        <div class="w-per100 h500 wow animate__animated animate__zoomIn" data-wow-duration="0.6s" data-wow-iteration="1" data-wow-delay="0.2s">
          <img class="w-per100 h-per100" :src="middle_img" alt="">
        </div>
        <div class="w-per100 mt52 richText2">
          
        </div>
      </div>
    </div> -->
    
    <div id="aboutus1" class="aboutus1 bg_w column a_center w-per100 minh700">
      <div class="w-per62_5 column a_center">
        <div class="c_294A3B ft32 mt50 wow animate__animated animate__zoomIn" data-wow-duration="0.6s" data-wow-iteration="1" data-wow-delay="0.2s">
          公司介绍
        </div>

        <div class="w-per100 row j_sb a_center mt110">
          <div class="w-per70">
            <div class="ft24">
              {{ about.title }}
            </div>
            <div class="mt30 ft18 richText1">
              
            </div>
          </div>
          <div class="w222 h170 row flex_center nowrap wow animate__animated animate__heartBeat" data-wow-duration="1.2s" data-wow-iteration="1" data-wow-delay="0.2s">
            <img class="w-per100 h-per100" :src="right_img" alt="">
          </div>
        </div>
      </div>
    </div>

    <!-- 关于岱微 -->
    <div class="aboutus2 bg_w column a_center w-per100 minh1000">
      <div class="w-per62_5 column a_center mt50">
        <div class="w-per100 row j_sb">
          <div class="ft32 mr60 mt30">
            关于岱微
          </div>
          <div class="flex1 br10 bg_w wow animate__animated animate__fadeInRight" data-wow-duration="0.6s" data-wow-iteration="1" data-wow-delay="0.2s">
            <div class="w-per100 h-per100 px48 pt40 pb52 richText2 bg_FFFFFF-0F9E73">
              
            </div>
          </div>
        </div>

        <div class="w-per100 relative mt96">

            <div class="wh650 mxauto row nowarp relative">
              <img class="w-per100 h-per100" src="https://www.daiweicd.com/commonImg/icon_kehuweizhongxin.png" alt="">
              <div class="w-per100 h-per100 absolute top0 left0 column flex_center">
                <img class="wh80" src="https://www.daiweicd.com/commonImg/icon_kehu.png" alt="">
                <div class="mt20 ft24 text_nowrap show_two">
                  以客户为中心
                </div>
              </div>
            </div>

            <div class="w-per100 h540 column j_sb absolute top26 left0 zIndex10 listBox">
              <div class="w-per100 row j_sb px50">
                <div class="row wow animate__animated animate__fadeInRight" data-wow-duration="0.6s" data-wow-iteration="1" data-wow-delay="0.1s">
                  <div class="relative w322 h104 row a_center nowrap">
                    <img class="w-per100 h-per100" :src="dw_car_list[0].background" alt="">
                    <div class="w-per100 h-per100 absolute top0 left0 zIndex10">
                      <div class="ml30 py16">
                        <div class="ft20 show_two">
                          {{ dw_car_list[0].title }}
                        </div>
                        <div class="mt12 ft16 info_color">
                          {{ dw_car_list[0].englishtitle }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="wh92 mt6 row a_center nowrap">
                    <img class="w-per100 h-per100" :src="dw_car_list[0].thumb" alt="">
                  </div>
                </div>
                <div class="row wow animate__animated animate__fadeInLeft" data-wow-duration="0.6s" data-wow-iteration="1" data-wow-delay="0.1s">
                  <div class="wh92 mt6 row a_center nowrap">
                    <img class="w-per100 h-per100" :src="dw_car_list[1].thumb" alt="">
                  </div>
                  <div class="relative w322 h104 row a_center nowrap">
                    <img class="w-per100 h-per100" :src="dw_car_list[1].background" alt="">
                    <div class="w-per100 h-per100 absolute top0 left0 zIndex10">
                      <div class="ml50 py16">
                        <div class="ft20 show_two">
                          {{ dw_car_list[1].title }}
                        </div>
                        <div class="mt12 ft16 info_color">
                          {{ dw_car_list[1].englishtitle }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="w-per100 row j_sb">
                <div class="row wow animate__animated animate__fadeInRight" data-wow-duration="0.6s" data-wow-iteration="1" data-wow-delay="0.2s">
                  <div class="relative w282 h104 row a_center nowrap">
                    <img class="w-per100 h-per100" :src="dw_car_list[2].background" alt="">
                    <div class="w-per100 h-per100 absolute top0 left0 zIndex10">
                      <div class="ml30 py16">
                        <div class="ft20 show_two">
                          {{ dw_car_list[2].title }}
                        </div>
                        <div class="mt12 ft16 info_color">
                          {{ dw_car_list[2].englishtitle }}
                        </div>
                      </div>
                    </div>
                    <div class="wh92 mt6 row a_center nowrap absolute zIndex10" style="top: -4.0625vw;right: -1.0417vw;">
                      <img class="w-per100 h-per100" :src="dw_car_list[2].thumb" alt="">
                    </div>
                  </div>
                </div>
                <div class="row wow animate__animated animate__fadeInLeft" data-wow-duration="0.6s" data-wow-iteration="1" data-wow-delay="0.1s">
                  <div class="relative w282 h104 row a_center nowrap">
                    <div class="wh92 mt6 row a_center nowrap absolute zIndex10" style="top: -4.0625vw;left: -1.0417vw;">
                      <img class="w-per100 h-per100" :src="dw_car_list[3].thumb" alt="">
                    </div>
                    <img class="w-per100 h-per100" :src="dw_car_list[3].background" alt="">
                    <div class="w-per100 h-per100 absolute top0 left0 zIndex10">
                      <div class="ml64 py16">
                        <div class="ft20 show_two">
                          {{ dw_car_list[3].title }}
                        </div>
                        <div class="mt12 ft16 info_color">
                          {{ dw_car_list[3].englishtitle }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="w-per100 row j_sb px50">
                <div class="row wow animate__animated animate__fadeInRight" data-wow-duration="0.6s" data-wow-iteration="1" data-wow-delay="0.3s">
                  <div class="relative w322 h104 row a_center nowrap">
                    <img class="w-per100 h-per100" :src="dw_car_list[4].background" alt="">
                    <div class="w-per100 h-per100 absolute top0 left0 zIndex10">
                      <div class="ml30 py16">
                        <div class="ft20 show_two">
                          {{ dw_car_list[4].title }}
                        </div>
                        <div class="mt12 ft16 info_color">
                          {{ dw_car_list[4].englishtitle }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="wh92 mt6 row a_center nowrap">
                    <img class="w-per100 h-per100" :src="dw_car_list[4].thumb" alt="">
                  </div>
                </div>
                <div class="row wow animate__animated animate__fadeInLeft" data-wow-duration="0.6s" data-wow-iteration="1" data-wow-delay="0.1s">
                  <div class="wh92 mt6 row a_center nowrap">
                    <img class="w-per100 h-per100" :src="dw_car_list[5].thumb" alt="">
                  </div>
                  <div class="relative w322 h104 row a_center nowrap">
                    <img class="w-per100 h-per100" :src="dw_car_list[5].background" alt="">
                    <div class="w-per100 h-per100 absolute top0 left0 zIndex10">
                      <div class="ml50 py16">
                        <div class="ft20 show_two">
                          {{ dw_car_list[5].title }}
                        </div>
                        <div class="mt12 ft16 info_color">
                          {{ dw_car_list[5].englishtitle }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

        </div>
      </div>
    </div>

    <!-- 高效专业有创意 -->
    <div class="aboutus3 bg_w column a_center w-per100 minh660">
      <div class="w-per62_5 h500 row nowrap mt100 br10 bg_w wow animate__animated animate__flipInX" data-wow-duration="0.6s" data-wow-iteration="1" data-wow-delay="0.2s">
        <div class="wh500 row nowrap br10">
          <img class="w-per100 h-per100" src="https://www.daiweicd.com/commonImg/chahua.png" alt="">
        </div>
        <div class="flex1 h510 relative">
          <img class="w-per101 h-per100" src="https://www.daiweicd.com/commonImg/beijing.png" alt="">
          <div class="w-per100 h500 absolute left0 top0 z_index10 pt70 px50">
            <div class="w-per100 richText3">
              
            </div>
            <div class="row mt68">
              <div class="column flex_center" :class="index>0?'ml100':''" v-for="(item,index) in small_icon" :key="index">
                <div class="wh50 row flex_center nowrap">
                  <img class="wh50" :src="item.thumb" alt="">
                </div>
                <div class="ft18 mt16">
                  {{ item.title }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 合作咨询 -->
    <div id="contactUs" class="consultBox column a_center w-per100 minh696">
      <div class="w-per62_5 column a_center">
        <div class="c_294A3B ft32 mt54 wow animate__animated animate__zoomIn" data-wow-duration="0.6s" data-wow-iteration="1" data-wow-delay="0.2s">
          合作咨询
        </div>

        <div class="w-per100 row j_end mt90 mb80">
          <div class="w500">
            <el-form :inline="true" :model="ruleForm" :rules="rules" class="">
              <el-form-item class="w-per100">
                <el-input v-model="ruleForm.name" placeholder="姓名">
                  <img slot="prefix" class="wh30 mt_icon" src="https://www.daiweicd.com/commonImg/Light1.png" alt="">
                </el-input>
              </el-form-item>
              <el-form-item class="w-per100">
                <el-input v-model="ruleForm.phone" placeholder="联系方式">
                  <img slot="prefix" class="wh30 mt_icon" src="https://www.daiweicd.com/commonImg/Light2.png" alt="">
                </el-input>
              </el-form-item>
              <el-form-item class="w-per100">
                <el-input v-model="ruleForm.remarks" placeholder="需求备注">
                  <img slot="prefix" class="wh30 mt_icon" src="https://www.daiweicd.com/commonImg/Light3.png" alt="">
                </el-input>
              </el-form-item>
            </el-form>
            <div class="w-per100 h60 bg_0F9E73-67CF90 mt50 column flex_center pointer" @click="to_sub">
              <div class="white_color ft20">
                提 交
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>

    <!-- footer -->
    <my-footer :current="current" @onScrollTo="scroll"></my-footer>
  </div>
</template>

<script>
import { getSeoList, getAbout, otherVal, consultSub } from "@/api/app"
export default {
  metaInfo() {
    return {
      title: '关于我们',
      meta: [
        {
          name: "keywords",
          content: this.PagekeyWords,
        },
        {
          name: "description",
          content: this.PageDescription,
        },
        {
          property: "og:type",
          content: 'website',
        },
        {
          property: "og:title",
          content: this.PageTitle,
        },
        {
          property: "og:description",
          content: this.PageDescription,
        },
        {
          property: "og:site_name",
          content: this.PageTitle,
        },
        {
          property: "og:url",
          content: 'https://www.daiweicd.com/#/aboutus',
        },
        {
          property: "og:image",
          content: 'https://www.daiweicd.com/favicon.ico',
        },
      ],
    };
  },
  data() {
    return {
      PageTitle: "",
      PagekeyWords: "",
      PageDescription: "",
      ruleForm: {
        name: "",//姓名
        phone: "",//联系方式
        remarks: "",
      },
      rules: {
        name: [{required: true, message: "请输入您的姓名", trigger: "blur"}],
        phone: [{required: true, message: "请输入联系方式", trigger: "blur"}],
        remarks: [{required: true, message: "请输入需求备注", trigger: "blur"}],
      },
      dw_car_list:[],
      about:{},
      about_two:'',
      about_img3:'',
      right_img:'',
      small_icon:[],
      otherdata:{},
      // title:'关于我们',
      current: 2,
    }
  },
  mounted() {
    this.fetchSeoList()

    this.fetchAbout()
    
  },
  
  methods: {
    async fetchSeoList() {
      let {data} = await getSeoList()
      this.tit = data.data.val
      // console.log('tt',this.tit);
      this.PageTitle = this.tit.zd_name?this.tit.zd_name:'岱微'
      this.PagekeyWords = this.tit.wz_keyword?this.tit.wz_keyword:'岱微'
      this.PageDescription = this.tit.zd_desc?this.tit.zd_desc:'成都岱微汽车服务有限公司'
    },
    // 提交
    async to_sub() {
      // console.log("表单", this.ruleForm);
      if(!this.checkForm()){
        return ;
      }else{
        let {data} = await consultSub({
          name: this.ruleForm.name,
          phone: this.ruleForm.phone,
          remarks: this.ruleForm.remarks,
        })
        if (data.code == 1) {
          this.$message({
            message: "提交成功",
            type: "success",
          })
          this.ruleForm.name = '';
          this.ruleForm.phone = '';
          this.ruleForm.remarks = '';
        } else {
          this.$message.error(data.msg)
        }
      }
    },
    checkForm(){
      if(this.ruleForm.name == ''){
        this.$message.error('请输入您的姓名');
        return false;
      }else if(this.ruleForm.phone == ''){
        this.$message.error('请输入联系方式');
        return false;
      }else if(this.ruleForm.remarks == ''){
        this.$message.error('请输入需求备注');
        return false;
      }else{
        return true;
      }
    },
    handleShow(index,id) {
      // console.log(id,index)
      this.current = index
      if (index == 0) {
        this.$router.push({
          path: "/",
        })
      }
      if (index == 1) {
        this.$router.push({
          path: "/product",
        })
      }
      this.handelUpdate()
      if(id){
        setTimeout(()=>{
          var el = document.getElementById(id)
          this.$nextTick(function () {
            window.scrollTo({
              behavior: "smooth",
              top: el.offsetTop + -88,
            })
          })
        },100)
      }else{
        return
      }
    },
    handelUpdate() {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      })
    },
    scroll({id, offset, num}) {
      // console.log("111")
      if (num == -1) {
        offset = offset || -10
        var el = document.getElementById(id)
        this.$nextTick(function () {
          window.scrollTo({
            behavior: "smooth",
            top: el.offsetTop + offset,
          })
        })
      } else {
        this.handleShow(num,id)
      }
    },
    async fetchAbout(){
      let {data} = await getAbout()
      // console.log('getAbout',data.data);
      this.about = data.data.our_serve.about;
      this.about_two = data.data.our_serve.about_two;
      this.about_three = data.data.our_serve.about_three;
      this.right_img = data.data.our_serve.right_img;
      this.small_icon = data.data.our_serve.small_icon;
      this.dw_car_list = data.data.our_serve.dw_car_list;

      this.fetchOtherVal()
    },
    async fetchOtherVal() {
      let {data} = await otherVal()
      this.otherdata = data.data;

      // console.log('other',this.otherdata);
      
      let consultBox = document.querySelector('.consultBox');
      consultBox.style.backgroundImage = `url(${this.otherdata.img_six})`
      consultBox.style.backgroundSize = "100% 100%"

      let aboutus1 = document.querySelector('.aboutus1');
      aboutus1.style.backgroundImage = `url(${this.otherdata.about_img1})`;
      aboutus1.style.backgroundSize = "100% 100%";
      aboutus1.style.backgroundRepeate = "no-repeat";

      let aboutus2 = document.querySelector('.aboutus2');
      aboutus2.style.backgroundImage = `url(${this.otherdata.about_img2})`;
      aboutus2.style.backgroundSize = "100% 100%";
      aboutus2.style.backgroundRepeat = "no-repeat";

      let aboutus3 = document.querySelector('.aboutus3');
      aboutus3.style.backgroundImage = `url(${this.otherdata.about_img3})`;
      aboutus3.style.backgroundSize = "100% 100%";
      aboutus3.style.backgroundRepeat = "no-repeat";

      let content1 = this.about.content.replaceAll('font-size: 18px;','')
      let content2 = this.about_two.replaceAll('font-size: 18px;','')
      let content3 = this.about_three.replaceAll('font-size: 18px;','')

      // let cont = this.about_two.replaceAll('font-size: 18px;','')
      // let arr = cont.split('<p><br/></p>');

      let richT1 = document.querySelector('.richText1')
      richT1.innerHTML = content1;
      let spanList = richT1.querySelectorAll('span')
      for (var i = 0; i < spanList.length; i++) {
        spanList[i].classList.add('ft18');
        spanList[i].classList.add('info_color');
      }

      let richT2 = document.querySelector('.richText2')
      richT2.innerHTML = content2.replaceAll(`style`,'style="text-wrap: wrap; margin-block-start: 0em;margin-block-end: 0em;line-height: 1.875vw;"');
      let spanList2 = richT2.querySelectorAll('span')
      for (var j = 0; j < spanList2.length; j++) {
        spanList2[j].classList.add('ft18');
        spanList2[j].classList.add('info_color');
      }

      let richT3 = document.querySelector('.richText3')
      richT3.innerHTML = content3.replaceAll(`style="text-wrap: wrap; line-height: normal;"`,'style="text-wrap: wrap; margin-block-start: 0em;margin-block-end: 0em;line-height: 1.875vw;"');
      let spanList3 = richT3.querySelectorAll('span')
      for (var k = 0; k < spanList3.length; k++) {
        spanList3[k].classList.add('ft18');
      }
    },
  },
}
</script>

<style scoped lang="scss">
.main{
  width: 100%;
  min-height: 100%;
  // padding-top: 5.2083vw;
  margin: 0px;
  background-color: #fff;
  position: relative;
  overflow-x: hidden;

  .richText1{
    span{
      font-size: 18px !important;
    }
    @media screen and (min-width: 1250px) and (max-width: 1500px){
      span{font-size: 16px !important;}
    }
    @media screen and (min-width: 750px) and (max-width: 1250px){
      span{font-size: 12px !important;}
    }
    @media screen and (max-width: 750px){
      span{font-size: 4px !important;}
    }
  }

  .listBox{
    .w322{width: 16.7708vw;}
    .w282{width: 14.6875vw;}
    .h104{height: 5.4167vw;}
    .richText2{
      span{
        font-size: 18px !important;
      }
    }
    @media screen and (min-width: 1250px) and (max-width: 1500px){
      span{font-size: 16px !important;}
    }
    @media screen and (min-width: 750px) and (max-width: 1250px){
      span{font-size: 12px !important;}
      .w322{
        width: 21.875vw;
      }
      .w282{
        width: 19.7917vw;
      }
      .h104{
        height: 6.25vw;
      }
    }
    @media screen and (max-width: 750px){
      .ft16{font-size: 6px !important;}
      span{font-size: 6px !important;}
      .w322{
        width: 24.4792vw;
      }
      .w282{
        width: 21.875vw;
      }
      .h104{
        height: 6.7708vw;
      }
    }
  }

  .richText3{
    span{
      font-size: 18px !important;
    }
    @media screen and (min-width: 1250px) and (max-width: 1500px){
      span{font-size: 16px !important;}
    }
    @media screen and (min-width: 750px) and (max-width: 1250px){
      span{font-size: 12px !important;}
    }
    @media screen and (max-width: 750px){
      span{font-size: 6px !important;}
    }
  }

  .minh660{
    
  }
  @media screen and (max-width: 750px){
    .minh660{
      min-height: 44.375vw;
      .wh500{
        width: 33.8542vw;
        height: 33.8542vw;
      }
      .h500{height: 33.8542vw;}
      .h510{height: 34.375vw;}
    }
  }

  .consultBox{
    ::v-deep .el-form-item{
      margin: 0;
    }
    ::v-deep .el-form--inline .el-form-item__content{
      width: 100%;
    }
    ::v-deep .el-input__inner.el-input__inner.el-input__inner{
      margin-top: 1.5625vw;
      padding-left: 2.0833vw;
      height: 3.125vw;
      color: #333333;
      background: rgba(255,255,255,0.7);
      border-radius: 0px;
      border: 1px solid #67CF90;
      outline: none;
    }
    .el-form-item__content{
        
    }
    @media screen and (min-width: 1250px) and (max-width: 1500px){
      ::v-deep .el-input__inner.el-input__inner.el-input__inner{
        font-size: 16px;
        padding-left: 2.0833vw;
      }
    }
    @media screen and (min-width: 750px) and (max-width: 1250px){
      ::v-deep .el-input__inner.el-input__inner.el-input__inner{
        font-size: 14px;
        padding-left: 2.2833vw;
      }
    }
    @media screen and (max-width: 750px){
      ::v-deep .el-form-item__content{
        line-height: 20px !important;
      }
      ::v-deep .el-input__inner.el-input__inner.el-input__inner{
        font-size: 6px;
        padding-left: 2.6833vw;
      }
    }
    ::v-deep .el-input__inner::placeholder {
      color:  #666666;
    }
  }

}

</style>
