import server from "@/utils/request";


// 发请求的操作封装成函数  mock

// 首页
export function getSeoList(data){
    return server({
        method:"POST",
        url:"/api/index/seo_list",
        data
    })
}

// 首页
export function getIndex(data){
    return server({
        method:"POST",
        url:"/api/index/index",
        data
    })
}
// 其他数据
export function otherVal(data){
    return server({
        method:"POST",
        url:"/api/index/other_val",
        data
    })
}
// 提交咨询
export function consultSub(data){
    return server({
        method:"POST",
        url:"/api/index/consult_sub",
        data
    })
}
// 全链营销
export function productView(data){
    return server({
        method:"POST",
        url:"/api/index/marketing",
        data
    })
}
// 关于我们
export function getAbout(data){
    return server({
        method:"POST",
        url:"/api/index/about",
        data
    })
}